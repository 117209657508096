import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input, DatePicker, Table, Select, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Menubar as MenuSales } from "../../../../components/SalesManager/App";
import { Menubar as MenuWhs } from "../../../../components/WhrManager/App";
import { http } from "../../../../services/http";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { debouncedSet } from "../../../../utils/debounce";
import Navbar from "../../../../components/navbar";
const { RangePicker } = DatePicker;

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const ActSverka = () => {
  const role = useSelector((state) => state.main.role);
  const [accData, setAccData] = useState([]);
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [accCode, setAccCode] = useState("");
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [currency, setCurrency] = useState("");

  const { t } = useTranslation();

  const fetchData = useCallback(
    async (page, accCode, SstartDate, SendDate) => {
      try {
        setLoading(true);

        let url = `api/sales/account-balance?accCode=${accCode}&${SstartDate ? `startDate=${SstartDate}&` : ""}${SendDate ? `endDate=${SendDate}&` : ""}skip=${page}`;

        const { data } = await http.get(url);
        const formattedData = data.data;
        const maxsulotLengths = formattedData.length;

        const hasMore = maxsulotLengths === 20 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [cardName, phoneNumber, startDate, endDate, slpName],
  );

  const fetchAccounts = useCallback(async (currency) => {
    try {
      setLoading(true);

      let url = `api/cashier/chart-of-accounts?currencyType=${currency}&skip=0`;

      const { data } = await http.get(url);
      const formattedData = data.data;

      setAccData(formattedData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(currentPage, accCode, startDate, endDate);
  }, [fetchData, accCode, currentPage, startDate, endDate]);

  useEffect(() => {
    if (currency) {
      fetchAccounts(currency);
    }
  }, [currency]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    debouncedSet(e.target.value, setStartDate);
  };

  const handleEndDate = (e) => {
    debouncedSet(e.target.value, setEndDate);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "phoneNumber") {
          if (value) {
            setPhoneNumber(value);
          } else {
            setPhoneNumber("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 2000),
    [fetchData, currentPage],
  );
  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "sana") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const column = (t, getColumnSearchProps) => {
    const columns = [
      {
        title: t("date"),
        dataIndex: "docDate",
        key: "sana",
        render: (value) => moment.utc(value).format("YYYY-MM-DD"),
      },

      {
        title: t("schotCode/BpCode"),
        dataIndex: "contraAct",
        key: "contraAct",
      },
      {
        title: t("schotName/BpName"),
        dataIndex: "contraName",
        key: "contraName",
      },
      {
        title: t("incoming-payments"),
        dataIndex: "debit",
        key: "debit",
        render: (text, record) => {
          const formatSum =
            currency === "UZS"
              ? `${new Intl.NumberFormat("fr-FR").format(record.fcDebit)} UZS`
              : `${new Intl.NumberFormat("fr-FR").format(text)} USD`;
          return `${formatSum} `;
        },
      },
      {
        title: t("outgoing-payments"),
        dataIndex: "credit",
        key: "credit",
        render: (text, record) => {
          const formatSum =
            currency === "UZS"
              ? `${new Intl.NumberFormat("fr-FR").format(record.fcCredit)} UZS`
              : `${new Intl.NumberFormat("fr-FR").format(text)} USD`;
          return `${formatSum} `;
        },
      },
      // {
      //   title: t("balanceAmount"),
      //   dataIndex: "cumulativeBalanceLC",
      //   key: "cumulativeBalanceLC",
      //   render: (text, record) => {
      //     const formatSum =
      //     currency === "UZS"
      //         ? `${new Intl.NumberFormat("fr-FR").format(record.cumulativBalanceFC)} UZS`
      //         : `${new Intl.NumberFormat("fr-FR").format(text)} USD`;
      //     return `${formatSum} `;
      //   },
      // },
      {
        title: t("initBalanceCash"),
        dataIndex: "cumulativeBalanceLC",
        key: "cumulativeBalanceLC",
        // render: (value) => value,
        render: (text, record) => {
          const formatSum =
            currency === "UZS"
              ? `${new Intl.NumberFormat("fr-FR").format(record.cumulativeBalanceFC)} UZS`
              : `${new Intl.NumberFormat("fr-FR").format(text)} USD`;
          return `${formatSum} `;
        },
      },

      {
        title: t("comments"),
        dataIndex: "memo",
        key: "memo",
      },
      {
        title: t("docNum"),
        dataIndex: "transId",
        key: "transId",
      },
    ];

    return columns;
  };

  const columns = column(t, getColumnSearchProps);

  return (
    <div className="flex h-full w-full overflow-y-auto">
      {role === "Whsmanager" ? <MenuWhs /> : <MenuSales />}
      <div className="h-screen w-full overflow-y-auto">
        <div className="h-screen w-full overflow-y-auto">
          <Navbar />
          <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
            {t("akt-sverka")}
          </h1>
          <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

          <div className="mt-6 flex w-full items-center justify-between gap-4 sm:mt-10 sm:gap-7">
            <div className="flex gap-5">
              <div className="ml-4 flex flex-col gap-3">
                <b>{t("currency")}</b>
                <Select
                  value={currency}
                  className="w-[150px]"
                  onChange={(val) => {
                    setCurrency(val);
                    // 5713 UZS 5714 USD

                    setAccCode(val === "UZS" ? "5713" : "5714");
                  }}
                  options={[
                    { value: "UZS", label: "UZS" },
                    { value: "USD", label: "USD" },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-3">
                <p>{t("startDate")}</p>
                <Input
                  type="date"
                  value={startDate}
                  className="w-[150px]"
                  onChange={handleStartDate}
                  max={endDate}
                />
              </div>
              <div className="flex flex-col gap-3">
                <p>{t("endDate")}</p>
                <Input
                  type="date"
                  value={endDate}
                  className="w-[150px]"
                  onChange={handleEndDate}
                  min={startDate}
                  max={formattedDate}
                />
              </div>
            </div>
          </div>
          <div className="ml-4 mt-6 sm:ml-5 sm:mt-10">
            {/* <div className="mb-4 flex flex-col justify-between sm:flex-row">
              <div className="font-nunitto font-bold">
                {t("page")} : {currentPage + 1}
              </div>
              <div className="mt-2 flex gap-2 sm:mr-10">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                  className="h-[40px] w-[100px] rounded-2xl bg-gray-300 text-gray-700 disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
                >
                  {t("previous")}
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={!hasMoreData}
                  className="h-[40px] w-[100px] rounded-2xl bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
                >
                  {t("next")}
                </button>
              </div>
            </div> */}
            {/* <div>
              <span>Kassa qoldig'i : </span>

              <span className="text-sm font-bold">
                {fdata.length === 0
                  ? 0
                  : currency === "UZS"
                    ? `${new Intl.NumberFormat("fr-FR").format(
                        fdata[0]?.cumulativeBalanceFC,
                      )} UZS`
                    : `${new Intl.NumberFormat("fr-FR").format(fdata[0]?.cumulativeBalanceLC)} USD`}
              </span>
            </div> */}
            <div className="overflow-x-auto">
              <Table
                columns={columns}
                dataSource={fdata}
                pagination={false}
                className="mt-6 w-full sm:mt-10"
                rowKey="zakaz"
                sticky={true}
                scroll={{ x: 900 }}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {(query.edit || query.add) && (
        <CreatePaymentAccount refetchTable={fetchData} />
      )} */}
    </div>
  );
};

export default ActSverka;
