import React, { useState, useEffect, useCallback } from "react";
import { Select } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import AcceptedModal from "../../../components/WhrManager/acceptedModal";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import moment from "moment";
import { debouncedSet } from "../../../utils/debounce";
const Accepted = () => {
  const { t } = useTranslation();
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [hasMore, setHasMore] = useState(moment().format("YYYY-MM-DD"));
  const [responsiblePersonData, setResponsiblePersonData] = useState([]);
  const [whsData, setWhsData] = useState([]);
  const [status, setStatus] = useState("");
  const [responsibleSearch, setResponsibleSearch] = useState("");

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "Tasdiqlandi", value: "Tasdiqlandi" },
    { label: "Mas'ulga biriktirildi", value: "Biriktirildi" },
    { label: "Buyurtma yuklandi", value: "BuyurtmaYuklandi" },
    { label: "Yuklash tasdiqlandi", value: "YuklashTasdiqlandi" },
  ];

  const fetchData = useCallback(
    async (status, responsible, StartDate, EndDate, page) => {
      try {
        setLoading(true);
        const { data } = await http.get(
          `api/sales/stock-transfer-requests?status=${status}&responsiblePersonId=${responsible}&startDate=${StartDate}&endDate=${EndDate}&skip=${page}`,
        );

        const formattedData = data.data;
        setHasMore(data.data.length === 20);
        setFData(formattedData);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [],
  );

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );
      setResponsiblePersonData(data.data);
      console.log("warehouse-employee", data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWarehouse = useCallback(async () => {
    try {
      const { data } = await http.get(`api/warehouses`);

      setWhsData(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchData(status, responsibleSearch, startDate, endDate, currentPage);
  }, [fetchData, status, responsibleSearch, startDate, endDate, currentPage]);

  useEffect(() => {
    fetchResponsibleManagers();
    fetchWarehouse();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleStartDate = (e) => {
    debouncedSet(e.target.value, setStartDate);
  };

  const handleEndDate = (e) => {
    debouncedSet(e.target.value, setEndDate);
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const handleCreateProductClick = () => {
    setIsCreateProductModalVisible(true);
  };

  const closeCreateProductModal = () => {
    setIsCreateProductModalVisible(false);
    fetchData(status, responsibleSearch, startDate, endDate, 0);
  };
  const closeViewModal = () => {
    setIsModalVisible(false);
    fetchData(status, responsibleSearch, startDate, endDate, 0);
  };

  const columns = [
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
    },

    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      render: (text) => {
        return <span>{moment(text).format("DD-MM-YYYY")}</span>;
      },
    },
    // {
    //   title: t("deliveryConfirmedTime"),
    //   dataIndex: "salesOrderTime",
    //   key: "salesOrderTime",
    //   width: 250,
    //   render: (text) => {
    //     return (
    //       <span>{text ? moment(text).format("DD-MM-YYYY HH:mm") : "-"}</span>
    //     );
    //   },
    // },
    {
      title: t("responsiblePerson"),
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => {
        return (
          <span>{`${`${text ?? ""} ${record?.lastName ?? ""}`}` || "-"}</span>
        );
      },
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 300 }}
          placeholder="Select Description"
          value={responsibleSearch}
          onChange={(value) => {
            setCurrentPage(0);
            setResponsibleSearch(value !== undefined ? value : "");
          }}
          options={responsiblePersonData.map((item) => {
            return {
              value: item.employeeId,
              label: item.firstName + " " + item.lastName,
            };
          })}
        />
      ),
    },
    {
      title: t("from-whs"),
      dataIndex: "fromWarehouse",
      key: "fromWarehouse",
      render: (text, record) => {
        const whs = text
          ? whsData.find((item) => {
              return item.warehouseCode === text;
            })
          : "-";

        return (
          <span>{`${whs ? `${whs?.warehouseName ?? ""}` : ""}` || "-"}</span>
        );
      },
    },

    {
      title: t("toWhs"),
      dataIndex: "toWarehouse",
      key: "toWarehouse",
      render: (text, record) => {
        const whs = text
          ? whsData.find((item) => {
              return item.warehouseCode === text;
            })
          : "-";

        return (
          <span>{`${whs ? `${whs?.warehouseName ?? ""}` : ""}` || "-"}</span>
        );
      },
    },
    // {
    //   title: t("OrderToDeliveryTime"),
    //   dataIndex: "orderToDeliveryTime",
    //   key: "orderToDeliveryTime",
    //   render: (text) => {
    //     return (
    //       <span>{text ? moment(text).format("DD-MM-YYYY HH:mm") : "-"}</span>
    //     );
    //   },
    // },
    // {
    //   title: t("deliveredTime2"),
    //   dataIndex: "orderDeliveredTime",
    //   key: "orderDeliveredTime",
    //   render: (text) => {
    //     return (
    //       <span>{text ? moment(text).format("DD-MM-YYYY HH:mm") : "-"}</span>
    //     );
    //   },
    // },
    {
      title: t("status"),
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (text, record) => {
        const status = record.orderStatus
          ? orderStatus.find((status) => {
              return status.value === record.orderStatus;
            })
          : "-";
        return <span>{status?.label ? status.label : status}</span>;
      },
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Description"
          value={status}
          onChange={(value) => {
            setCurrentPage(0);
            setStatus(value !== undefined ? value : "");
          }}
          options={orderStatus.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          })}
        />
      ),
    },
  ];

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Header
          title={"accepted"}
          currentPage={currentPage + 1}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          hasMoreData={hasMore}
          columns={columns}
          fdata={fdata}
          loading={loading}
          handleRowClick={handleRowClick}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
          startDate={startDate}
          endDate={endDate}
          formattedDate={moment().format("YYYY-MM-DD")}
          scrollX={1200}
        />
        <AcceptedModal
          visible={isModalVisible}
          data={selectedRowData}
          onClose={closeViewModal}
        />
      </div>
    </div>
  );
};

export default Accepted;
