import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, Select } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import BuyModal from "../../../components/WhrManager/buyModal";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import PurchasementModal from "../../../components/WhrManager/purchasementModal";
import { aggregatePurchases } from "../../../utils/document";
import { get } from "lodash";
import moment from "moment";
import { debouncedSet } from "../../../utils/debounce";

const Purchasement = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [total, setTotal] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [docStatus, setDocStatus] = useState("");
  const [cardName, setCardName] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedCardName, setSelectedCardName] = useState("");
  const [selectedDocNum, setSelectedDocNum] = useState("");
  const [driver, setDriver] = useState("");

  const fetchData = useCallback(
    async (DocNum, CardName, driver, page, startDate, endDate, docStatus) => {
      try {
        setLoading(true);
        setDataSource([]);

        let url = " ";
        if (selectedCardName === "" && selectedDocNum === "") {
          url = `api/purchaseinvoices?docNum=${DocNum}&driver=${driver}&cardName=${CardName}&pageToken=${page}&docStatus=${docStatus}&startDate=${startDate}&endDate=${endDate}`;
        } else {
          url = `api/purchaseinvoices?docNum=${DocNum}&driver=${driver}&cardName=${CardName}&pageToken=${page}&docStatus=${docStatus}&startDate=${startDate}&endDate=${endDate}`;
        }

        const { data } = await http.get(url);

        const newCardName = new Set(CardName);
        const uniqueEntries = new Set();

        data.docs.filter((item) => {
          const key = `${item.cardName}`;

          if (uniqueEntries.has(key)) {
            return false;
          }
          uniqueEntries.add(key);
          newCardName.add(item.cardName);

          return true;
        });

        setCardName([...newCardName]);
        const formattedData = aggregatePurchases(data.docs);
        setDataSource(formattedData);
        const hasMore = formattedData.length === 10 ? true : false;
        setHasMoreData(hasMore);
        setTotal(get(data, "total", 0));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [selectedCardName, selectedDocNum],
  );

  const fetchDriver = async () => {
    try {
      const { data } = await http.get(
        `api/sales/driver-business-partners?isKassir=true`,
      );
      setDriverData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (value, key) => {
    switch (key) {
      case "cardName":
        value !== undefined
          ? setSelectedCardName(value)
          : setSelectedCardName("");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    fetchDriver();
  }, []);

  useEffect(() => {
    fetchData(
      selectedDocNum,
      selectedCardName,
      driver,
      currentPage,
      startDate,
      endDate,
      docStatus,
    );
  }, [
    fetchData,
    selectedDocNum,
    driver,
    selectedCardName,
    currentPage,
    startDate,
    endDate,
    docStatus,
  ]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleStartDate = (e) => {
    debouncedSet(e.target.value, setStartDate);
  };

  const handleEndDate = (e) => {
    debouncedSet(e.target.value, setEndDate);
  };

  const handleCreateProductClick = () => {
    setIsCreateProductModalVisible(true);
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const closeCreateProductModal = () => {
    setIsCreateProductModalVisible(false);
    setSearchText("");
    setSelectedCardName("");
    setStartDate("");
    setEndDate("");
    setDriver("");
    setSelectedDocNum("");
    setCurrentPage(1);
    fetchData(
      selectedDocNum,
      selectedCardName,
      driver,
      currentPage,
      startDate,
      endDate,
      docStatus,
    );
  };

  let searchInput = null;

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "docNum") {
          if (value) {
            setSelectedDocNum(value);
          } else {
            setSelectedDocNum("");
          }
        } else if (dataIndex === "docStatus") {
          if (value) {
            setCurrentPage(1);
            setDocStatus(value);
          } else {
            setDocStatus("");
          }
        }
        confirm();
      }, 1000),
    [],
  );

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;

    debouncedSearch(value, dataIndex, confirm);
  };

  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "docStatus") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <Select
              value={selectedKeys[0] || ""}
              onChange={(value) => {
                setSelectedKeys(value ? [value] : [""]);
                debouncedSearch(value, dataIndex, confirm);
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              options={[
                {
                  value: "",
                  label: t("all"),
                },
                {
                  value: "O",
                  label: t("open"),
                },
                {
                  value: "C",
                  label: t("closed"),
                },
              ]}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (value === "") {
            return true;
          }
          return record[dataIndex]
            ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
            : "";
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const column = [
    {
      title: t("namePur"),
      dataIndex: "mijoz",
      key: "mijoz",
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Item Group"
          value={selectedCardName}
          onChange={(value) => handleSelectChange(value, "cardName")}
        >
          {cardName.map((group) => (
            <Select.Option key={group} value={group}>
              {group}
            </Select.Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.mijoz === value,
    },

    {
      title: t("numPur"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },

    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
      render: (text, record) => {
        const driver = text
          ? driverData.find((item) => {
              return item.cardCode === text;
            })
          : "-";

        return (
          <span>{`${driver ? `${driver?.cardName ?? ""} ` : "-"}` || "-"}</span>
        );
      },
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Item Group"
          value={driver}
          onChange={(value) => setDriver(value)}
        >
          <Select.Option key={null} value={""}></Select.Option>
          {driverData.map((driver) => (
            <Select.Option key={driver.cardCode} value={driver.cardCode}>
              {driver.cardName}
            </Select.Option>
          ))}
        </Select>
      ),
      // onFilter: (value, record) => record.mijoz === value,
    },
    {
      title: t("sumPur"),
      dataIndex: "summa",
      key: "summa",
      render: (text, record) => {
        const formatSum =
          record.docCur === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.docTotalFC)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.docCur}`;
      },
    },
    {
      title: t("docStatus"),
      dataIndex: "docStatus",
      key: "docStatus",
      ...getColumnSearchProps("docStatus"),
      render: (text) => {
        return text === "O" ? t("open") : text === "C" ? t("closed") : text;
      },
    },
  ];

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Header
          title={"purchasement"}
          currentPage={currentPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          hasMoreData={hasMoreData}
          onBtnClick={handleCreateProductClick}
          columns={column}
          fdata={dataSource}
          loading={loading}
          handleRowClick={handleRowClick}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
          startDate={startDate}
          endDate={endDate}
          formattedDate={moment().format("YYYY-MM-DD")}
          total={total}
        />
        <BuyModal
          visible={isCreateProductModalVisible}
          onClose={closeCreateProductModal}
        />

        <PurchasementModal
          visible={isModalVisible}
          data={selectedRowData}
          onClose={() => setIsModalVisible(false)}
        />
      </div>
    </div>
  );
};

export default Purchasement;
