import React, { useEffect, useState } from "react";
import Menubar from "../../components/SalesManager/App";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import { http } from "../../services/http";
import { getToday } from "../../utils/getDays";
import {
  formatMoney,
  handleInputChangeMoney,
  removeSpaces,
} from "../../utils/formatMoney";

const ConversionPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [inputType, setInputType] = React.useState("USD");
  const [outputType, setOutputType] = React.useState("UZS");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async (values) => {
    try {
      setSubmitLoading(true);
      console.log(submitLoading);
      await http.post("api/cashier/convertation", {
        from: values.type === 1 ? "USD" : "UZS",
        to: values.type === 1 ? "UZS" : "USD",
        rate: +removeSpaces(values.docRate),
        sum: +removeSpaces(values.input),
      });
      message.success(t("Successfully completed"));
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  };
  useEffect(() => {
    console.log(submitLoading);
  }, [submitLoading]);
  const handleCalculate = () => {
    const values = form.getFieldsValue();
    if (values.input && values.docRate) {
      if (values.type === 1) {
        form.setFieldsValue({
          output: formatMoney(
            +removeSpaces(values.input) * removeSpaces(values.docRate),
          ),
        });
      } else {
        form.setFieldsValue({
          output: formatMoney(
            +removeSpaces(values.input) / +removeSpaces(values.docRate),
          ),
        });
      }
    }
  };

  const handleChangeConversion = (value) => {
    if (value === 1) {
      setInputType("USD");
      setOutputType("UZS");
    }
    if (value === 2) {
      setInputType("UZS");
      setOutputType("USD");
    }
  };

  const getCurrencyRate = async (value) => {
    if (!value) {
      form.setFieldsValue({ docRate: "" });
      return;
    }
    try {
      setLoading(true);
      const { data } = await http.get(
        `api/cashier/currency-rate?Currency=UZS&Date=${value}`,
      );
      form.setFieldsValue({ docRate: formatMoney(data.data) });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <div>
          <div className={"ml-4 mt-10 flex gap-2 sm:ml-10 sm:mt-14"}>
            <h1 className="font-poppins text-xl font-bold text-black sm:text-2xl">
              {t("Conversion")}
            </h1>
            <Spin spinning={loading} />
          </div>
          <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14" />

          <div className={"mt-10 flex justify-center"}>
            <Form
              initialValues={{ type: 1 }}
              form={form}
              layout={"vertical"}
              className={"w-full md:w-[700px]"}
              onFinish={handleSubmit}
            >
              <Row gutter={{ sm: 10 }}>
                <Col span={12}>
                  <Form.Item name={"type"} label={t("Konvertatsiya turi")}>
                    <Select
                      options={[
                        { label: "usd to uzs", value: 1 },
                        { label: "uzs to usd", value: 2 },
                      ]}
                      onChange={handleChangeConversion}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={"date"} label={t("date")}>
                    <DatePicker
                      className={"w-full"}
                      format="DD.MM.YYYY"
                      onChange={getCurrencyRate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name={"docRate"} label={t("Dollar kursi")}>
                <Input
                  onChange={(e) => handleInputChangeMoney(e, form, "docRate")}
                />
              </Form.Item>
              <Row gutter={{ sm: 10 }}>
                <Col span={12}>
                  <Form.Item name={"input"} label={inputType}>
                    <Input
                      onChange={(e) => handleInputChangeMoney(e, form, "input")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={"output"} label={outputType}>
                    <Input
                      onChange={(e) =>
                        handleInputChangeMoney(e, form, "output")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className={"flex justify-end gap-2"}>
                <Button onClick={handleCalculate}>{t("Hisoblash")}</Button>
                <Button
                  type={"primary"}
                  htmlType={"submit"}
                  loading={submitLoading}
                >
                  {t("save")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionPage;
