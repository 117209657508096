import React, { useState } from "react";
import { Modal, Input, Table, Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { http } from "../../services/http";

const PurchaseDeliveryModal = ({ visible, data, onClose, refetch }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState("");

  if (!data) return null;

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("measureUnit"),
      dataIndex: "unitsOfMeasurment",
      key: "unitsOfMeasurment",
    },
  ];

  const editManager = async () => {
    setLoading(true);

    try {
      const stockTransferLines = data.documentLines.map((item, idx) => {
        return {
          itemCode: item.itemCode,
          quantity: item.quantity,
          lineNum: item.lineNum,
        };
      });
      await http.post(
        `api/sales/create-inventory-transfer-request-permission`,
        {
          docEntry: data.docEntry,
          stockTransferLines,
        },
      );
      message.success(t("sucCreated"));

      setTimeout(() => {
        onClose();
        refetch();
      }, 800);
    } catch (error) {
      console.log("Error");
      message.error(error?.response?.data?.message);
      console.log("Error:", error?.response?.data?.message?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-5">
        <div className="w-full">
          <div className="mt-5 flex items-center justify-between gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("namePur")}</p>
              <Input
                type="text"
                value={data?.cardName}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("typeOfTransport")}</p>
              <Input
                type="text"
                value={data.typeOfTransport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("numberOfTransport")}</p>
              <Input
                type="text"
                value={data.numberOfTransport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("partyNumber")}</p>
              <Input
                type="text"
                value={data.partyNumber || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("status")}</p>
              <Input
                type="text"
                value={data.requesForShipping || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data?.documentLines}
            pagination={false}
            className="border-1 mt-4 w-full border-black sm:mt-12"
            scroll={{ x: "100%" }}
          />

          <div className="mt-5 flex items-center justify-end gap-5">
            <Button
              onClick={editManager}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
              disabled={loading || data.requesForShipping !== "SurovYuborilsin"}
              loading={loading}
            >
              {t("deliveryPost")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PurchaseDeliveryModal;
