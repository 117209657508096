import { Button } from "antd";
import moment from "moment";
import { formatMoney } from "./formatMoney";

export function columnAct(t, getColumnSearchProps) {
  const columns = [
    {
      title: t("date"),
      dataIndex: "refDate",
      key: "refDate",
      render: (value) => (value ? moment.utc(value).format("DD.MM.YYYY") : ""),
    },
    {
      title: t("Schot kodi / BP kodi"),
      dataIndex: "contraAct",
      key: "contraAct",
    },
    {
      title: t("Schot nomi / BP nomi"),
      dataIndex: "contraName",
      key: "contraName",
    },

    {
      title: t("Kirim puli"),
      dataIndex: "debit",
      key: "debit",
      render: (_, record) => (
        <p>
          {record?.account === "5711"
            ? new Intl.NumberFormat("fr-FR").format(record.debit)
            : new Intl.NumberFormat("fr-FR").format(record.fcDebit)}
        </p>
      ),
    },
    {
      title: t("Chiqim puli"),
      dataIndex: "credit",
      key: "credit",
      render: (_, record) => (
        <p>
          {record?.account === "5711"
            ? new Intl.NumberFormat("fr-FR").format(record.credit)
            : new Intl.NumberFormat("fr-FR").format(record.fcCredit)}
        </p>
      ),
    },
    {
      title: t("Qoldiq summa"),
      dataIndex: "cumulativeBalanceFC",
      key: "cumulativeBalanceFC",
      width: 200,
      render: (_, record) => (
        <p>
          {record?.account === "5711"
            ? new Intl.NumberFormat("fr-FR").format(record.cumulativeBalanceLC)
            : new Intl.NumberFormat("fr-FR").format(record.cumulativeBalanceFC)}
        </p>
      ),
    },

    {
      title: t("Izoh"),
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: t("Hujjat raqami"),
      dataIndex: "transId",
      key: "transId",
    },
  ];

  return columns;
}

export function columnAcceptPaymentCustomer(t, getColumnSearchProps) {
  const columns = [
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "sana",
      render: (value) => moment(value).format("DD.MM.YYYY"),
    },
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "mijoz",
      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("phoneNum"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getColumnSearchProps("phoneNumber"),
      render: (value) => (value !== "-" ? value : ""),
    },
    {
      title: t("Dollar kursi"),
      dataIndex: "docRate",
      key: "docRate",
      render: (value) => formatMoney(value),
    },
    {
      title: t("priceSum"),
      dataIndex: "cashSum",
      key: "cashSum",
      render: (_, record) =>
        record.docCurrency === "UZS"
          ? formatMoney(record.cashSumFC)
          : formatMoney(record.cashSum),
    },
    {
      title: t("currency"),
      dataIndex: "docCurrency",
      key: "docCurrency",
    },
  ];

  return columns;
}

export function column(
  t,
  getColumnSearchProps,
  driver = false,
  status = false,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
      // ...getColumnSearchProps("summa"),
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    });
  }
  if (status) {
    columns.push({
      title: t("status"),
      dataIndex: "docStatus",
      key: "status",
    });
  }

  return columns;
}

export function column5(t, getColumnSearchProps) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
      // ...getColumnSearchProps("summa"),
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },
  ];

  return columns;
}

export function column2(
  t,
  getColumnSearchProps,
  driver = false,
  action = false,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    });
  }
  if (action) {
    columns.push({
      title: t("ship"),
      key: "action",
      render: () => (
        <Button className="bg-[#0A4D68] text-white">{t("ship")}</Button>
      ),
    });
  }

  return columns;
}

export function columnConfirmedSales(
  t,
  getColumnSearchProps,
  driver = false,
  action = false,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "cardName",
      text: "center",

      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      text: "center",

      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      text: "center",
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "-"),
    },

    {
      title: t("priceSum"),
      dataIndex: "docTotal",
      key: "docTotal",
      render: (text, record) => {
        const formatSum =
          record.docCur === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.docTotalFc)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.docCur}`;
      },
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    });
  }
  if (action) {
    columns.push({
      title: t("status"),
      key: "action",
    });
  }

  return columns;
}

export function columnShippedSales(
  t,
  getColumnSearchProps,
  driver = false,
  action = false,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "cardName",
      text: "center",

      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      text: "center",

      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      text: "center",
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "-"),
    },

    {
      title: t("priceSum"),
      dataIndex: "docTotal",
      key: "docTotal",
      render: (text, record) => {
        const formatSum =
          record.docCurrency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.docTotalFc)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.docCurrency}`;
      },
    },

    {
      title: t("status"),
      key: "status",
    },

    {
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    },
    {
      title: t("remove"),
      key: "remove",
    },
  ];

  return columns;
}

export function column3(
  t,
  getColumnSearchProps,
  driver = false,
  remove = false,
  onClick,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },

    {
      title: t("Status"),
      dataIndex: "docStatus",
      key: "docStatus",
      ...getColumnSearchProps("docStatus"),

      // render: () => <Button className="bg-[#0A4D68] text-white">{t("remove")}</Button>,
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
      render: (value) => (value ? value : "-"),
    });
  }

  if (remove) {
    columns.push({
      title: t("remove"),
      dataIndex: "remove",
      key: "remove",
    });
  }

  return columns;
}

export function columnShippedSale(t, getColumnSearchProps, remove = true) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "cardName",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      text: "center",
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "-"),
      // ...getColumnSearchProps("sana"),
    },

    {
      title: t("priceSum"),
      dataIndex: "docTotal",
      key: "docTotal",
      render: (text, record) => {
        const formatSum =
          record.docCur === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.docTotalFc)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.docCur}`;
      },
    },

    {
      title: t("Status"),
      dataIndex: "orderStatus",
      key: "orderStatus",
      ...getColumnSearchProps("orderStatus"),
    },
    {
      title: t("driver"),
      dataIndex: "driverCardName",
      key: "driverCardName",
    },
  ];
  if (remove) {
    columns.push({
      title: t("remove"),
      dataIndex: "remove",
      key: "remove",
    });
  }

  return columns;
}

export function columnFinishedSales(t, getColumnSearchProps) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "cardName",
      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      text: "center",
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "-"),
      // ...getColumnSearchProps("sana"),
    },

    {
      title: t("priceSum"),
      dataIndex: "docTotal",
      key: "docTotal",
      render: (text, record) => {
        const formatSum =
          record.docCur === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.docTotalFc)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.docCur}`;
      },
    },

    {
      title: t("Status"),
      dataIndex: "orderStatus",
      key: "orderStatus",
      ...getColumnSearchProps("orderStatus"),
    },
    {
      title: t("driver"),
      dataIndex: "driverCardName",
      key: "driverCardName",
    },
    {
      title: t("docStatus"),
      dataIndex: "docStatus",
      key: "docStatus",
      ...getColumnSearchProps("docStatus"),
    },
  ];

  return columns;
}

export function columnFinishedSalesManager(t, getColumnSearchProps) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numFinish"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
      render: (text, record) => {
        const formatSum =
          record.docCur === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.docTotalFC)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.docCur}`;
      },
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },

    {
      title: t("Status"),
      dataIndex: "docStatus",
      key: "docStatus",
      ...getColumnSearchProps("docStatus"),
      render: (value) =>
        value === "O" ? t("open") : value === "C" ? t("closed") : "-",
    },
    {
      title: t("driver"),
      dataIndex: "driverCardName",
      key: "driverCardName",
      render: (value) => (value ? value : "-"),
    },
  ];

  return columns;
}
