import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import Auth from "../pages/auth";
import MySales from "../pages/whrManager/sales/mysales/mysales";
import DepartmentSales from "../pages/whrManager/sales/from-department/departmentSales";
import Shipments from "../pages/whrManager/sales/shipments";
import FinishedSales from "../pages/whrManager/sales/finishedSales";
import Sales from "../pages/salesManager/sales/sales";
import ShipmentItems from "../pages/salesManager/shipments";
import FinishedSalesManager from "../pages/salesManager/finishedSales";
import ClientsSales from "../pages/salesManager/clients/clients";
import Debtors from "../pages/salesManager/debtors";
import Reports from "../pages/whrManager/reports";
import InProcess from "../pages/whrManager/ombor/inProccess/inProcess";
import AcceptedProducts from "../pages/whrManager/ombor/accepted";
import Purchasement from "../pages/whrManager/purchasement/purchasement";
import Dashboard from "../pages/dashboard";
import ReportsSales from "../pages/salesManager/reports";
import Tracking from "../pages/tracking";
import ShippedReports from "../pages/shippedReports";
import ReturnedItems from "../pages/returnedItems";
import AcceptPaymentCustomer from "../pages/salesManager/incoming-payment/accept-payment-customer";
import AcceptPaymentDelivery from "../pages/salesManager/incoming-payment/accept-payment-delivery";
import AcceptPaymentAccount from "../pages/salesManager/incoming-payment/accept-payment-account";
import SupplierPayment from "../pages/salesManager/outgoing-payments/supplier-payment";
import ActSverki from "../pages/salesManager/akt-sverki/act-sverki";
import ConversionPage from "../pages/salesManager/conversion";
import CreatePaymentPage from "../pages/salesManager/outgoing-payments/create-payment";
import IncomingPayments from "../pages/whrManager/cash/incoming-payments";
import OutgoingPayments from "../pages/whrManager/cash/outgoing-payments";
import ActSverka from "../pages/whrManager/cash/act-sverka";
import PurchaseDelivery from "../pages/whrManager/purchaseDelivery/purchasement";
import DriverReports from "../pages/whrManager/driverReports";
import EmployeeReports from "../pages/whrManager/employeeReports";
const ProtectedRoute = ({ element: Component, roles = [], ...rest }) => {
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
  const role = useSelector((state) => state.main.role);

  if (!isAuthenticated) {
    return <Navigate to="/auth" replace />;
  }

  if (!roles) {
    return <Navigate to="/" replace />;
  }

  return <Component {...rest} />;
};

const RoutesComponent = () => {
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
  const role = useSelector((state) => state.main.role);
  const MySalesComponent = role === "Seller" ? Sales : MySales;
  const MyShipmentItems = role === "Seller" ? ShipmentItems : Shipments;
  const MyFinishedSalesManager =
    role === "Seller" ? FinishedSalesManager : FinishedSales;
  const MyReports = role === "Seller" ? ReportsSales : Reports;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to={isAuthenticated ? "/my-sales" : "/auth"} replace />
        }
      />
      <Route path="/auth" element={<Auth />} />
      <Route
        path="/my-sales"
        element={<ProtectedRoute element={MySalesComponent} />}
      />
      <Route
        path="/incoming-payments-wrh"
        element={<ProtectedRoute element={IncomingPayments} />}
      />
      <Route
        path="/outgoing-payments-wrh"
        element={<ProtectedRoute element={OutgoingPayments} />}
      />
      <Route
        path="/act-sverka-wrh"
        element={<ProtectedRoute element={ActSverka} />}
      />
      <Route
        path="/purchase-delivery"
        element={<ProtectedRoute element={PurchaseDelivery} />}
      />
      <Route
        path="/driver-reports"
        element={<ProtectedRoute element={DriverReports} />}
      />
      <Route
        path="/employee-reports"
        element={<ProtectedRoute element={EmployeeReports} />}
      />
      <Route
        path="/returned-items"
        element={<ProtectedRoute element={ReturnedItems} />}
      />
      <Route path="/reports" element={<ProtectedRoute element={MyReports} />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute element={Dashboard} />}
      />
      <Route path="/tracking" element={<ProtectedRoute element={Tracking} />} />
      <Route
        path="/shippedReports"
        element={<ProtectedRoute element={ShippedReports} />}
      />
      <Route path="/debtors" element={<ProtectedRoute element={Debtors} />} />
      <Route
        path="/clients"
        element={<ProtectedRoute element={ClientsSales} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/accepting-payment-from-customer"
        element={<ProtectedRoute element={AcceptPaymentCustomer} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/accepting-payment-from-delivery"
        element={<ProtectedRoute element={AcceptPaymentDelivery} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/accepting-payment-from-account"
        element={<ProtectedRoute element={AcceptPaymentAccount} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/outgoing-payment-supplier"
        element={<ProtectedRoute element={SupplierPayment} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/create-payment"
        element={<ProtectedRoute element={CreatePaymentPage} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/act-sverki"
        element={<ProtectedRoute element={ActSverki} />}
      />
      <Route
        roles={role === "Cashier"}
        path="/conversion"
        element={<ProtectedRoute element={ConversionPage} />}
      />
      {role !== "Seller" && (
        <>
          <Route
            path="/from-department"
            element={<ProtectedRoute element={DepartmentSales} />}
          />
          <Route
            path="/purchasement"
            element={<ProtectedRoute element={Purchasement} />}
          />
          <Route
            path="/inProcess"
            element={<ProtectedRoute element={InProcess} />}
          />
          <Route
            path="/accepted"
            element={<ProtectedRoute element={AcceptedProducts} />}
          />
        </>
      )}
      <Route
        path="/shipments"
        element={<ProtectedRoute element={MyShipmentItems} />}
      />
      <Route
        path="/closing-sale"
        element={<ProtectedRoute element={MyFinishedSalesManager} />}
      />
    </Routes>
  );
};

export default RoutesComponent;
