import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Select, Spin, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getFiveDaysAgo, getToday } from "../../../utils/getDays";
import { http } from "../../../services/http";
import { useSearchParams } from "react-router-dom";
import { useRouterPush } from "../../../hooks/use-router-push";
import { useLocationParams } from "../../../hooks/use-location-params";
import moment from "moment";

const CreateIncomingPayments = ({ isModalVisible, refetchTable, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [partnersLoading, setPartnersLoading] = useState(false);
  const [rateLoading, setRateLoading] = useState(false);
  const [docDate, setDocDate] = useState(moment().format("YYYY-MM-DD"));
  const [partnersData, setPartnersData] = useState([]);
  const [docCurrency, setDocCurrency] = useState("USD");
  const { query } = useLocationParams();
  const [skip, setSkip] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);

  const handleScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      setSkip((prevSkip) => {
        const newSkip = prevSkip + 1;
        getBP(newSkip);
        return newSkip;
      });
    }
  };

  const getCurrencyRate = async (docDate) => {
    try {
      setRateLoading(true);
      const { data } = await http.get(
        `api/cashier/currency-rate?Currency=UZS&Date=${docDate}`,
      );
      form.setFieldsValue({ docRate: data.data });
    } catch (err) {
      console.log(err);
    } finally {
      setRateLoading(false);
    }
  };
  const getBP = async (newSkip) => {
    if (!hasMoreData) return;
    try {
      setRateLoading(true);
      const { data } = await http.get(
        `api/sales/business-partners?skip=${newSkip * 20 || 0}&cardType=cSupplier&isKassir=true`,
      );
      const mappedItems = data.data.map((user) => ({
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{user.cardName}</span>
            <Tag color="blue">{user.currency}</Tag>
          </div>
        ),
        value: user.cardCode,
      }));
      if (data.data.length < 20) {
        setHasMoreData(false);
      }
      setPartnersData((prevData) => [...prevData, ...mappedItems]);
    } catch (err) {
      console.log(err);
    } finally {
      setRateLoading(false);
    }
  };

  useEffect(() => {
    getBP();
    form.setFieldsValue({
      docDate: getToday(),
    });
  }, []);

  useEffect(() => {
    getCurrencyRate(docDate);
  }, [docDate]);

  const postData = async (values) => {
    try {
      setLoading(true);
      await http.post("api/sales/outgoing-payment", {
        ...values,
        // + "T00:00:00Z"
        docDate: values.docDate + "T00:00:00Z",

        docType: "rSupplier",
        cashSum: +values.cashSum,
      });
      refetchTable();
      message.success({ content: t("sucCreated") });
      onClose();
    } catch (err) {
      message.error({ content: JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      open={isModalVisible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "80%" }}
    >
      <Spin spinning={rateLoading || loading}>
        <div className="w-full sm:px-14">
          <Form
            form={form}
            layout={"vertical"}
            onFinish={postData}
            initialValues={{
              docCurrency: "USD",
            }}
          >
            <div className="mt-10 grid gap-3 sm:grid-cols-1 md:grid-cols-3">
              <Form.Item name="docDate" label={t("date")}>
                <Input
                  type="date"
                  min={getFiveDaysAgo()}
                  max={getToday()}
                  value={docDate}
                  onChange={(e) => {
                    setDocDate(e.target.value);
                  }}
                  className="h-12 font-bold"
                />
              </Form.Item>
              <Form.Item name="cardCode" label={t("driverAndEmployee")}>
                <Select
                  showSearch
                  className="h-12 font-normal"
                  options={partnersData}
                  loading={partnersLoading}
                  onPopupScroll={handleScroll}
                />
              </Form.Item>
              <Form.Item name="docRate" label={t("Dollar kursi")}>
                <Input className="h-12" />
              </Form.Item>
              <Form.Item name="cashSum" label={t("Cash sum")}>
                <Input className="h-12" type={"number"} />
              </Form.Item>
              <Form.Item name="docCurrency" label={t("currency")}>
                <Select
                  onChange={(value) => {
                    setDocCurrency(value);
                  }}
                  showSearch
                  className="h-12"
                  options={[
                    { label: t("USD"), value: "USD" },
                    { label: t("UZS"), value: "UZS" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="remarks" label={t("comments")}>
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
            <div className="mt-24 flex justify-center">
              <Button
                htmlType={"submit"}
                className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
                disabled={loading}
                loading={loading}
              >
                {t("create")}
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateIncomingPayments;
