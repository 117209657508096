import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Input, Table } from "antd";
import Menubar from "../../components/SalesManager/App";
import { http } from "../../services/http";
import RowDataModal from "../../components/row-data";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import { columnFinishedSalesManager } from "../../utils/columns";
import { aggregateDocumentsSales } from "../../utils/document";
import moment from "moment";
import { get } from "lodash";
import Navbar from "../../components/navbar";

const FinishedSalesManager = () => {
  const [loading, setLoading] = useState(true);
  const [fdata, setFData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [docStatus, setDocStatus] = useState("O");
  const [total, setTotal] = useState(0);

  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);
  const { t } = useTranslation();
  let searchInput = "";

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setCurrentPage(1);
    setFData([]);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    setCurrentPage(1);
    setFData([]);
  };

  const fetchData = useCallback(
    async (
      page,
      ScardName,
      SdocNum,
      SslpName,
      sDocStatus,
      SstartDate,
      SendDate,
      isLoadMore = false,
    ) => {
      if (isLoadingRef.current) return;

      try {
        isLoadingRef.current = true;
        if (!isLoadMore) {
          setLoading(true);
        } else {
          setIsLoadingMore(true);
        }

        const url = `api/yakunlangansotuvlar?cardName=${ScardName}&docNum=${SdocNum}&slpName=${SslpName}&docStatus=${sDocStatus}&startDate=${SstartDate}&endDate=${SendDate}&pageToken=${page}&pageSize=30`;

        const { data } = await http.get(url);
        const formattedData = aggregateDocumentsSales(data);

        if (page === 1) {
          setFData(formattedData);
        } else {
          setFData((prevData) => [...prevData, ...formattedData]);
        }

        setHasMoreData(formattedData.length === 30);
        setTotal(get(data, "total", 0));
      } catch (error) {
        console.error(error);
        setHasMoreData(false);
      } finally {
        setLoading(false);
        setIsLoadingMore(false);
        isLoadingRef.current = false;
      }
    },
    [],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "mijoz") {
          setCardName(value || "");
        } else if (dataIndex === "docNum") {
          setDocNum(value || "");
        } else if (dataIndex === "docStatus") {
          setDocStatus(value || "");
        } else if (dataIndex === "yaratdi") {
          setSlpName(value ? value.toUpperCase() : "");
        } else if (dataIndex === "sana" && value) {
          setStartDate(moment(value[0]).format("YYYY-MM-DD"));
          setEndDate(moment(value[1]).format("YYYY-MM-DD"));
        }
        setFData([]);
        setCurrentPage(1);
        confirm();
      }, 2000),
    [],
  );

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const debouncedSetPage = useMemo(
    () =>
      debounce(() => {
        if (!isLoadingRef.current) {
          setCurrentPage((prev) => prev + 1);
        }
      }, 300),
    [],
  );

  const handleScroll = useCallback(
    (e) => {
      if (!hasMoreData || loading || isLoadingMore || isLoadingRef.current)
        return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        debouncedSetPage();
      }
    },
    [hasMoreData, loading, isLoadingMore, debouncedSetPage],
  );

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("search")} ${dataIndex}`}
          onChange={(e) => handleSearch(e, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = columnFinishedSalesManager(t, getColumnSearchProps);

  useEffect(() => {
    fetchData(
      currentPage,
      cardName,
      docNum,
      slpName,
      docStatus,
      startDate,
      endDate,
      currentPage > 1,
    );
  }, [
    fetchData,
    currentPage,
    cardName,
    docNum,
    slpName,
    startDate,
    endDate,
    docStatus,
  ]);

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.getElementsByClassName("ant-table-body")[0];
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => tableBody.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
          {t("finishedSales")}
        </h1>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

        <div className="ml-5 mt-5 flex gap-5">
          <div className="flex flex-col gap-3">
            <p>{t("startDate")}</p>
            <Input
              type="date"
              value={startDate}
              className="w-[150px]"
              onChange={handleStartDate}
              max={endDate}
            />
          </div>
          <div className="flex flex-col gap-3">
            <p>{t("endDate")}</p>
            <Input
              type="date"
              value={endDate}
              className="w-[150px]"
              onChange={handleEndDate}
              min={startDate}
              max={moment().format("YYYY-MM-DD")}
            />
          </div>
        </div>

        <div className="mt-10 overflow-x-auto" ref={tableContainerRef}>
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            loading={loading || isLoadingMore}
            rowKey={(record) => record.docEntry}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            scroll={{
              y: "calc(100vh - 400px)",
              scrollToFirstRowOnChange: false,
            }}
            sticky={{
              offsetHeader: 0,
              offsetScroll: 0,
              getContainer: () => window,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3}>
                    <strong>{t("total")}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    <strong>
                      {new Intl.NumberFormat("fr-FR").format(total)} USD
                    </strong>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>
      {isModalVisible && (
        <RowDataModal
          visible={isModalVisible}
          data={selectedRowData}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </div>
  );
};

export default FinishedSalesManager;
