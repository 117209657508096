import React, { useState, useEffect, useCallback } from "react";
import { Input, Table } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Navbar from "../../../components/navbar";

const DriverReports = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [deliveredWeight, setDeliveredWeight] = useState("");
  const [shippedWeight, setShippedWeight] = useState("");
  const [paidPrice, setPaidPrice] = useState("");
  const [shippingPriceTotal, setShippingPriceTotal] = useState("");
  const [deliveredPriceTotalDriver, setDeliveredPriceTotalDriver] =
    useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [leftPrice, setLeftPrice] = useState("");

  const fetchData = useCallback(async (startDate, endDate) => {
    try {
      setLoading(true);
      setDataSource([]);

      let url = `api/sales/driver-report?${startDate ? `fromDate=${startDate}&` : ""}toDate=${endDate}`;

      const { data } = await http.get(url);

      setDataSource(data.data);
      setDeliveredWeight(
        data.data.reduce((acc, item) => acc + item.deliveredWeight, 0),
      );
      setShippedWeight(
        data.data.reduce((acc, item) => acc + item.shippedWeight, 0),
      );

      setPaidPrice(data.data.reduce((acc, item) => acc + item.paidPrice, 0));
      setLeftPrice(data.data.reduce((acc, item) => acc + item.leftPrice, 0));
      setShippingPriceTotal(
        data.data.reduce((acc, item) => acc + item.shippingPriceTotal, 0),
      );
      setDeliveredPriceTotalDriver(
        data.data.reduce((acc, item) => acc + item.deliveredPriceTotal, 0),
      );
      setTotalPrice(data.data.reduce((acc, item) => acc + item.totalPrice, 0));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [fetchData, startDate, endDate]);

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const columns = [
    {
      title: t("driverCode"),
      dataIndex: "cardCode",
      key: "cardCode",
    },
    {
      title: t("driverName"),
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: t("deliveredWeightDriver"),
      dataIndex: "deliveredWeight",
      key: "deliveredWeight",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("shippedWeightDriver"),
      dataIndex: "shippedWeight",
      key: "shippedWeight",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },

    {
      title: t("pricePerKgDriver"),
      dataIndex: "shippingWeight",
      key: "shippingWeight",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("shippingPrice"),
      dataIndex: "shippingPrice",
      key: "shippingPrice",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("shippingPriceTotalDriver"),
      dataIndex: "shippingPriceTotal",
      key: "shippingPriceTotal",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("deliveredPriceTotalDriver"),
      dataIndex: "deliveredPriceTotal",
      key: "deliveredPriceTotal",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("totalPrice3"),
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("paidPrice"),
      dataIndex: "paidPrice",
      key: "paidPrice",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
    {
      title: t("leftPrice"),
      dataIndex: "leftPrice",
      key: "leftPrice",
      text: "center",
      render: (text) => {
        return new Intl.NumberFormat("fr-FR").format(text) || "-";
      },
    },
  ];

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <div className="h-screen w-full overflow-y-auto">
          <Navbar />
          <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
            {t("driverReports")}
          </h1>
          <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

          <div className="mt-6 flex w-full items-center gap-4 px-4 sm:mt-10 sm:gap-7 sm:px-10">
            <div className="flex flex-col gap-3">
              <p>{t("startDate")}</p>
              <Input
                type="date"
                value={startDate}
                className="w-[150px]"
                onChange={handleStartDate}
                max={endDate}
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("endDate")}</p>
              <Input
                type="date"
                value={endDate}
                className="w-[150px]"
                onChange={handleEndDate}
                min={startDate}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="m-2 overflow-x-auto">
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              className="mt-6 w-full sm:mt-10"
              rowKey="zakaz"
              sticky={true}
              scroll={{ x: 1700 }}
              loading={loading}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    className="font-bold text-black"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={1}
                    className="font-bold text-black"
                  >
                    {t("total")}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell
                    index={2}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(deliveredWeight)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(shippedWeight)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell
                    index={5}
                    className="font-bold text-black"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={6}
                    className="font-bold text-black"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={7}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(shippingPriceTotal)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(
                      deliveredPriceTotalDriver,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(totalPrice)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={9}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(paidPrice)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={10}
                    className="font-bold text-black"
                  >
                    {new Intl.NumberFormat("fr-FR").format(leftPrice)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverReports;
