import React, { useState, useEffect, useCallback } from "react";
import { Menubar as MenuSales } from "../components/SalesManager/App";
import { Menubar as MenuWhs } from "../components/WhrManager/App";
import { http } from "../services/http";
import { useTranslation } from "react-i18next";
import { formattedTracking, calculateTotalTracking } from "../utils/document";
import { useSelector } from "react-redux";
import { Table, Select } from "antd";
import Navbar from "../components/navbar";

const { Option } = Select;

const Tracking = () => {
  const { t } = useTranslation();
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const role = useSelector((state) => state.main.role);
  const [totals, setTotals] = useState({});

  const [descriptions, setDescriptions] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const [transportTypes, setTransportTypes] = useState([]);
  const [trackings, setTrackings] = useState([]);
  const [transportNumbers, setTransportNumbers] = useState([]);
  const [docDates, setDocDates] = useState([]);

  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedItemGroup, setSelectedItemGroup] = useState("");
  const [selectedTransportType, setSelectedTransportType] = useState("");
  const [selectedTracking, setSelectedTracking] = useState("");
  const [selectedTransportNumber, setSelectedTransportNumber] = useState("");
  const [selectedDocDate, setSelectedDocDate] = useState("");

  const fetchData = useCallback(
    async (
      selectedDescription,
      selectedItemGroup,
      selectedTransportType,
      selectedTracking,
      selectedTransportNumber,
      selectedDocDate,
    ) => {
      setLoading(true);
      try {
        const { data } = await http.get(
          `api/deliverynotesdata/onthewayitems/by-filter?description=${selectedDescription}&itemGroupName=${selectedItemGroup}&transportNumber=${selectedTransportNumber}&tracking=${selectedTracking}&typeOfTransport=${selectedTransportType}&docDate=${selectedDocDate}`,
        );

        const newDescriptions = new Set(descriptions);
        const newItemGroups = new Set(itemGroups);
        const newTransportTypes = new Set(transportTypes);
        const newTrackings = new Set(trackings);
        const newTransportNumbers = new Set(transportNumbers);
        const newDocDates = new Set(docDates);

        const uniqueEntries = new Set();

        data.filter((item) => {
          const key = `${item.dscription}-${item.itmsGrpNam}-${item.u_typeOfTransport}-${item.u_tracking}-${item.u_numberOfTransport}-${item.docDate}`;

          if (uniqueEntries.has(key)) {
            return false;
          }

          uniqueEntries.add(key);
          newDescriptions.add(item.dscription);
          newItemGroups.add(item.itmsGrpNam);
          newTransportTypes.add(item.u_typeOfTransport);
          newTrackings.add(item.u_tracking);
          newTransportNumbers.add(item.u_numberOfTransport);
          newDocDates.add(item.docDate);

          return true;
        });

        setDescriptions([...newDescriptions]);
        setItemGroups([...newItemGroups]);
        setTransportTypes([...newTransportTypes]);
        setTrackings([...newTrackings]);
        setTransportNumbers([...newTransportNumbers]);
        setDocDates([...newDocDates]);
        const formattedData = formattedTracking(data);
        setTotals(calculateTotalTracking(data));
        setFData(formattedData);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    fetchData(
      selectedDescription,
      selectedItemGroup,
      selectedTransportType,
      selectedTracking,
      selectedTransportNumber,
      selectedDocDate,
    );
  }, [
    fetchData,
    selectedDescription,
    selectedItemGroup,
    selectedTransportType,
    selectedTracking,
    selectedTransportNumber,
    selectedDocDate,
  ]);

  const handleSelectChange = (value, key) => {
    switch (key) {
      case "description":
        value !== undefined
          ? setSelectedDescription(value)
          : setSelectedDescription("");
        break;
      case "itemGroup":
        value !== undefined
          ? setSelectedItemGroup(value)
          : setSelectedItemGroup("");

        break;
      case "transportType":
        value !== undefined
          ? setSelectedTransportType(value)
          : setSelectedTransportType("");

        break;
      case "tracking":
        value !== undefined
          ? setSelectedTracking(value)
          : setSelectedTracking("");

        break;
      case "transportNumber":
        value !== undefined
          ? setSelectedTransportNumber(value)
          : setSelectedTransportNumber("");

        break;
      case "docDate":
        value !== undefined
          ? setSelectedDocDate(value)
          : setSelectedDocDate("");

        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: t("dscription"),
      dataIndex: "dscription",
      key: "dscription",
      width: 300,
      fixed: "left",
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 350 }}
          placeholder="Select Description"
          value={selectedDescription}
          onChange={(value) => handleSelectChange(value, "description")}
        >
          {descriptions.map((desc) => (
            <Option key={desc} value={desc}>
              {desc}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.dscription === value,
    },
    {
      title: t("itmsGrpNam"),
      dataIndex: "itmsGrpNam",
      key: "itmsGrpNam",
      width: 150,

      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Item Group"
          value={selectedItemGroup}
          onChange={(value) => handleSelectChange(value, "itemGroup")}
        >
          {itemGroups.map((group) => (
            <Option key={group} value={group}>
              {group}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.itmsGrpNam === value,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "unitMsr",
      key: "unitMsr",
      width: 150,
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "invQty",
      width: 150,

      key: "invQty",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
      width: 150,
    },
    {
      title: t("u_typeOfTransport"),
      dataIndex: "u_typeOfTransport",
      key: "u_typeOfTransport",
      width: 150,

      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Transport Type"
          value={selectedTransportType}
          onChange={(value) => handleSelectChange(value, "transportType")}
        >
          {transportTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.u_typeOfTransport === value,
    },
    {
      title: t("u_numberOfTransport"),
      dataIndex: "u_numberOfTransport",
      key: "u_numberOfTransport",
      width: 150,

      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Transport Number"
          value={selectedTransportNumber}
          onChange={(value) => handleSelectChange(value, "transportNumber")}
        >
          {transportNumbers.map((number) => (
            <Option key={number} value={number}>
              {number}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.u_numberOfTransport === value,
    },
    {
      title: t("u_tracking"),
      dataIndex: "u_tracking",
      key: "u_tracking",
      width: 150,

      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Tracking"
          value={selectedTracking}
          onChange={(value) => handleSelectChange(value, "tracking")}
        >
          {trackings.map((tracking) => (
            <Option key={tracking} value={tracking}>
              {tracking}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.u_tracking === value,
    },
    {
      title: t("partyNumber"),
      dataIndex: "partyNumber",
      key: "partyNumber",
      width: 150,
    },
    {
      title: t("shipDate"),
      dataIndex: "docDate",
      key: "docDate",
      width: 150,
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Document Date"
          value={selectedDocDate}
          onChange={(value) => handleSelectChange(value, "docDate")}
        >
          {docDates.map((date) => (
            <Option key={date} value={date}>
              {date}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.docDate === value,
    },
  ];

  return (
    <div className="flex w-full">
      {role === "Whsmanager" ? <MenuWhs /> : <MenuSales />}
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
          {t("tracking")}
        </h1>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>
        <div className="ml-10">
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            className="mt-6 w-full sm:mt-10"
            rowKey="zakaz"
            sticky={true}
            loading={loading}
            scroll={{ x: 1200 }}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  colSpan={1}
                  className="font-bold text-black"
                >
                  {t("total")}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={3}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(totals.invQty)}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={5}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={7}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={8}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  className="font-bold text-black"
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Tracking;
